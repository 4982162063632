import { ReactElement, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { CreativeDataProps, ErrorDataProps } from "types/Types";
import { ErrorPage } from "app/Pages";
import { CreativeService, DebugService, ReferenceService } from "services";
import { LeftPanel, RightPanel } from "./components";
import styles from "./DemoPage.module.scss";
import { deviceType } from "../../../services/ReferenceService/ReferenceService";

function DemoPage({ forcePreview }: { forcePreview?: boolean }): ReactElement {
  const [previewParams] = useSearchParams();
  const { environmentDictionary } = ReferenceService.dictionary;

  // required
  const adUnitParam = previewParams.get(ReferenceService.urlParameters.AU) || "";
  const creativeIdParam = previewParams.get(ReferenceService.urlParameters.CID) || "";

  const advertiserNameParam = previewParams.get(ReferenceService.urlParameters.A);
  const publisherIdParam = previewParams.get(ReferenceService.urlParameters.P);
  const creativeTypeParam = previewParams.get(ReferenceService.urlParameters.CTY) || "";
  const clickUrl = previewParams.get(ReferenceService.urlParameters.URL) || "";
  const deviceParam = previewParams.get(ReferenceService.urlParameters.DEVICE) || deviceType.MOBILE;

  const environmentParam = previewParams.get(ReferenceService.urlParameters.E) ?? environmentDictionary[0].key;

  const [errorData, setErrorData] = useState<ErrorDataProps>();
  const [creativeData, setCreativeData] = useState<CreativeDataProps>();

  async function getCreativeParams() {
    try {
      DebugService.info(`Calling CreativeParams API with creative's ID ${creativeIdParam}`);
      const data = await CreativeService.getCreativeData(creativeIdParam);
      const creativeSize = data.params.creative_size ? data.params.creative_size.split("x") : [];
      setCreativeData({
        creativeId: creativeIdParam,
        mediaType: data?.adFormat?.mediaType,
        inArticleType: data?.adFormat?.inArticleType,
        adUnit: adUnitParam,
        environment: environmentParam,
        publisherId: publisherIdParam ?? ReferenceService.constants.DEFAULT_DIRECTORY_NAME,
        dimension: creativeSize.length === 2 ? { width: creativeSize[0], height: creativeSize[1] } : undefined,
        // @ts-ignore
        advertiserName: advertiserNameParam || data?.advertiser?.name,
        creativeRatio: data?.params?.creative_ratio || 0,
        clickUrl,
      });
    } catch (_error) {
      DebugService.error(
        // @ts-ignore
        `The call to the CreativeParams API with creative's ID ${creativeIdParam} had failed with code ${_error?.response?.status} and error message: ${_error?.response?.data?.message}`
      );
      setErrorData({
        type: ReferenceService.errorType.CREATIVE_NOT_EXIST,
      });
    }
  }

  useEffect(() => {
    const isCreativeTypeOK = ReferenceService.dictionary.creativeTypeDictionary.map(({ key }) => key).includes(creativeTypeParam) || creativeTypeParam === "";
    const isEnvironmentOK = ReferenceService.dictionary.environmentDictionary.find((obj) => obj.key === environmentParam);
    const isAdUnitOK = ReferenceService.dictionary
      .adUnitDictionary()
      .map(({ key }) => key)
      .includes(adUnitParam);

    const missingParams = [];
    const wrongParams = [];

    if (!adUnitParam) {
      missingParams.push(ReferenceService.urlParameters.AU);
    }
    if (!creativeIdParam) {
      missingParams.push(ReferenceService.urlParameters.CID);
    }
    if (!isCreativeTypeOK) {
      wrongParams.push({ param: ReferenceService.urlParameters.CTY, value: creativeTypeParam });
    }
    if (!isEnvironmentOK) {
      wrongParams.push({ param: ReferenceService.urlParameters.E, value: environmentParam });
    }
    if (!isAdUnitOK) {
      wrongParams.push({ param: ReferenceService.urlParameters.AU, value: adUnitParam });
    }

    if (missingParams.length > 0) {
      DebugService.error(`The URL parameters "${missingParams.join(", ")}" are missing.`);
      setErrorData({
        type: ReferenceService.errorType.MISSING_PARAM,
      });
    } else if (wrongParams.length > 0) {
      wrongParams.forEach((wrongParam) => DebugService.error(`The URL parameter "${wrongParam.param}" with value "${wrongParam.value}" is incorrect.`));
      setErrorData({
        type: ReferenceService.errorType.PARAM_WRONG,
      });
    } else {
      getCreativeParams();
    }
  }, []);

  const handleDeviceChange = (device: string) => {
    previewParams.set(ReferenceService.urlParameters.DEVICE, device);
    window.location.href = `${window.location.pathname}?${previewParams.toString()}`;
  };

  if (errorData) {
    return <ErrorPage error={errorData} />;
  }

  return (
    <div className={styles.mainContainer}>
      {creativeData !== undefined && (
        <>
          {!forcePreview && <LeftPanel device={deviceParam} onDeviceChange={handleDeviceChange} creativeData={creativeData} />}
          <RightPanel forcePreview={forcePreview} device={deviceParam} creativeData={creativeData} />
        </>
      )}
    </div>
  );
}
export default DemoPage;
