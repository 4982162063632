import axios from "axios";
import { URLService } from "../index";

class AdUnitsService {
  adUnits = [];

  async initialize() {
    if (this.adUnits.length === 0) {
      const response = await axios.get(URLService.getAdUnitsURL(), { timeout: 5000 });
      this.adUnits = response?.data?.content;
    }
    return null;
  }
}
export default new AdUnitsService();
