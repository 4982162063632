import { DebugService, EnvService } from "services";
import { deviceType } from "../ReferenceService/ReferenceService";

class URLService {
  apiURL: string;

  resourceURL: string;

  adUnitsUrl: string;

  publicURL: string;

  mwebTagScriptURL: string = "https://mwtw.presage.io/v6.19.10/ogy-loader.js";

  constructor() {
    if (EnvService.isProduction()) {
      this.apiURL = "https://creative-console-api.prod.cloud.ogury.io/creatives/";
      this.resourceURL = "https://demo-showcase.prod.cloud.ogury.io/resources/";
      this.publicURL = "https://demo.ogury.co/page/";
      this.adUnitsUrl = "https://api.ogury.com/ocs/api/1/public/units";
    } else if (EnvService.isStaging()) {
      this.apiURL = "https://creative-console-api.staging.cloud.ogury.io/creatives/";
      this.resourceURL = "https://demo-showcase.staging.cloud.ogury.io/resources/";
      this.publicURL = "https://demo-showcase.staging.cloud.ogury.io/page/";
      this.adUnitsUrl = "https://gateway-tyk-oss.staging.cloud.ogury.io/ocs/api/1/public/units";
    } else {
      this.apiURL = "https://creative-console-api.devc.cloud.ogury.io/creatives/";
      this.resourceURL = "https://demo-showcase.devc.cloud.ogury.io/resources/";
      this.publicURL = "https://demo-showcase.devc.cloud.ogury.io/page/";
      this.adUnitsUrl = "https://gateway-tyk-oss.devc.cloud.ogury.io/ocs/api/1/public/units";
    }
    DebugService.info(`App initialized with AdExperience endpoint "${this.apiURL}"`);
    DebugService.info(`App initialized with resources endpoint "${this.resourceURL}"`);
    DebugService.info(`App initialized with mWeb tag endpoint "${this.mwebTagScriptURL}"`);
    DebugService.info(`App initialized with adUnits endpoint "${this.adUnitsUrl}"`);
    DebugService.info(`App initialized with public URL "${this.publicURL}"`);
  }

  getApiURL() {
    return this.apiURL;
  }

  getResourceURL() {
    return this.resourceURL;
  }

  getPublicURL() {
    return this.publicURL;
  }

  getMwebTagScriptURL() {
    return this.mwebTagScriptURL;
  }

  getAdUnitsURL() {
    return this.adUnitsUrl;
  }

  getPreviewEndpointURL(creativeId: string, adUnit: string = "", clickUrl: string = "", device: string = deviceType.MOBILE) {
    return `${this.getApiURL()}${creativeId}/preview.js?au=${adUnit}&deviceType=${device}&redirect=${encodeURIComponent(clickUrl)}`;
  }
}

export default new URLService();
